<template>
  <div>
    <a-table
      :columns="column"
      :data-source="dataTable"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      bordered
      size="large"
    >
    </a-table>
  </div>
</template>

<script>
import { jenisPrestasiList, tingkatPrestasiList } from '@/helpers/listsPpdbSelect'
const column = [
  {
    dataIndex: 'jenis',
    key: 'jenis',
    title: 'Jenis',
    scopedSlots: { customRender: 'jenis' },
    align: 'left',
  },
  {
    dataIndex: 'tingkat',
    key: 'tingkat',
    title: 'Tingkat',
    scopedSlots: { customRender: 'tingkat' },
    align: 'left',
  },
  {
    dataIndex: 'nama',
    key: 'nama',
    title: 'Nama',
    scopedSlots: { customRender: 'nama' },
    align: 'left',
  },
  {
    dataIndex: 'peringkat',
    key: 'peringkat',
    title: 'Peringkat',
    scopedSlots: { customRender: 'peringkat' },
    align: 'left',
  },
  {
    dataIndex: 'tahun',
    key: 'tahun',
    title: 'Tahun',
    scopedSlots: { customRender: 'tahun' },
    align: 'left',
  },
  {
    dataIndex: 'penyelenggara',
    key: 'penyelenggara',
    title: 'Penyelenggara',
    scopedSlots: { customRender: 'penyelenggara' },
    align: 'left',
  },
]
export default {
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      column,
      jenisPrestasiList,
      tingkatPrestasiList,
      dataTable: [],
    }
  },
  computed: {
  },
  methods: {
  },
  created() {
    this.dataTable = this.datas.prestasis.map(data => {
      return {
        ...data,
        key: data.id,
      }
    })
  },
}
</script>

<style>
</style>
